<template>
  <div class="base" v-loading="fullscreenLoading">

    <el-row class="button-area">

      <el-col :span="24">
        <img src="@/assets/image/tradelist/icon_filter.png" class="button-icon">
        <span :class="{
          'button-base':true,
          }" @click="showHistoryPicker">
          日期筛选
          </span>
        {{ dateduration }}
      </el-col>
    </el-row>

    <div class="split-line"></div>

    <el-row>
      <el-col :xs="24" :sm="12" v-for="subitem of datalist" :key="subitem['orderNo']"
              @click.native="showDetail(subitem)">
        <div class="task-box">
          <div class="task-context">
            <!--            <img :src="imgurl[subitem['type']]" class="img-style">-->
            <div class="word-area">
              <div class="wordsize">{{ subitem['createdAt'] }}</div>
              <div class="wordsize2">账户名: {{ subitem.accountName }}</div>
            </div>
          </div>
          <div class="task-button-area" :style="{'color':'#333333'}">
            +{{ (parseFloat(subitem['trxAmount']) / 100.0).toFixed(2) }}
          </div>
        </div>
        <div class="split-line"></div>
      </el-col>

      <!--      <el-col class="collapse-footer-span"><span>以上是该日的全部交易记录</span></el-col>-->
    </el-row>


    <calendar :show.sync="datepicker_is_visable" mode="during" @change="onChange" :maxDate="maxDate"/>
    <!--        <span style="font-size: 8px;line-height: 10px; color: #EBEBEB; height: 50px;padding-bottom: 20px;">{{dateduration}}</span>-->

  </div>
</template>

<script>
import Calendar from 'vue-mobile-calendar';

export default {
  name: "getcash_history",
  components: {
    inlineCalendar: Calendar.inlineCalendar,
    calendar: Calendar.calendar
  },
  data() {
    return {
      datepicker_is_visable: false,
      income_text: "",
      income_value: "",
      isAsc: false,
      page: 1,
      listHeight: "",
      maxDate: "",
      isPullingUp: false,
      fullscreenLoading: false,
      bsScroll: "",
      merName: "",
      activeNames: "",
      start_date: "",
      end_date: "",
      dateduration: "",
      logo: {},
      pageend: false,
      loading: false,
      status: "0",
      id: "1",
      imgurl: {},
      datalist: [],
      // {
      //   "createdAt": "2222",
      //   "trxAmount": 50000,
      //   "accountName": "test",
      // }

    }
  },
  methods: {
    //
    showDetail(item) {
      // console.log(item)
      // this.$router.push({path:"/h5/tradedetail",query:item})
    },
    changeCollapse(name, index) {
      // console.log(name)
      // console.log(this.activeNames)
      if (this.activeNames.length > 0) {
        this.requestDailyList(name, index)
      }
    },
    onChange(date) {
      let start_d = null
      let end_d = null;
      // console.log(date)
      if (date.length == 2) {
        start_d = date[0].format("YYYY-MM-DD")
        end_d = date[1].format("YYYY-MM-DD")
      }
      if (start_d && end_d) {
        this.start_date = start_d;
        this.end_date = end_d;
        this.dateduration = " " + start_d + "~" + end_d;
        this.refreshData()

      }
      // console.log(start_d)
      // console.log(end_d)
    },
    showQR() {
      this.isAsc = !this.isAsc;
      this.refreshData()
    },
    closeQR() {

    },
    showHistoryPicker() {
      this.datepicker_is_visable = true;

    },
    getContentHeight() {
      let windowheight = document.documentElement.clientHeight
      let taskheight = windowheight - 55
      this.listHeight = taskheight.toString() + "px"
      // this.bsScroll.refresh()
    },
    backtop() {
      this.bsScroll.scrollTo(0, 0, 300)
    },
    init_bsscroll() {


    },
    scrollHandler(pos) {
      if (pos.y < -1000) {
        this.backtopVisible = true
      } else {
        this.backtopVisible = false
      }

    },
    async pullingDownHandler() {

    },
    async finishPullDown() {
      // console.log('finish pullDown')
      // this.bsScroll.finishPullDown()
      setTimeout(() => {
        this.bsScroll.refresh()
      }, 100)
    },
    async pullingUpHandler() {
      if (!this.pageend) {
        this.isPullingUp = true
        this.page += 1
        this.refreshData()
        // console.log("pullingUP")
      }
    },
    refreshData() {
      this.merName = localStorage.getItem('merchantName')
      let _this = this;
      let params = {
        "startDate": this.start_date,
        "endDate": this.end_date,
        "page": this.page,
        "pageSize": 20,
        "merId": localStorage.getItem("merchantId"),
        // "isAsc": this.isAsc
      }
      Http.post('/frontend/merchant/getOutPaymentList', params).then(result => {
        if (result.code === 200) {
          // console.log(result)
          if (_this.page == 1) {
            _this.datalist = []
          }
          _this.processList(result.data)
          _this.$forceUpdate()
          // _this.income_value = result.data.amount;
          // _this.income_text = _this.income_is_visable ? (_this.income_value / 100.0).toFixed(2).toString() : "****"

        } else {
          _this.$message.error(result.message || "查询失败！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    requestDailyList(date, idx) {

    },
    processList(infoList) {
      let i = 0
      for (let item of infoList) {
        if (item['date']) {
          item['list'] = []
          let dt = new Date(item['date']);
          // console.log(dt)
          item['dateString'] = dt.getFullYear().toString() + "年" + (dt.getMonth() + 1).toString() + "月" + dt.getDate().toString() + "日";
        }
        this.datalist.push(item)
      }

    },
    processDetailList(infoList) {

    },
  },
  mounted() {
    this.maxDate = new Date().toDateString();

    this.$nextTick(() => {
      this.init_bsscroll()
    })
    this.getContentHeight()
    this.merName = localStorage.getItem('merchantName')
    this.refreshData()

  },
  created() {
    document.title = "提现记录"
  },
  computed: {

    isShowQR() {
      return this.$store.state.showQR;
    },

  },
}
</script>

<style scoped>
.base {
  background-color: rgb(246, 248, 255);
  text-align: center;

}

.logo {
  width: 100%;
  height: calc(100vw * 451.5 / 750);
}

.logo > img {
  /*width: 101%;*/
}

.split-line {
  width: 100vw;
  margin-left: 0vw;
  height: 1px;
  background: #9A9A9A;
  opacity: 0.4;
  margin-bottom: 0px;

}

.button-area {
  height: 52px;
  background: #FFFFFF;
  /*opacity: 0.5;*/
  margin-top: 1px;
  margin-bottom: 1px;
}

.button-base {
  display: inline-block;
  width: 70px;
  height: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  /*color: #F1BD20;*/
  line-height: 15px;
  opacity: 0.8;
  font-size: 14px;
  margin-top: 20px;

  text-align: center;

}

.today-income {
  width: 100vw;
  height: 15px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  opacity: 0.8;
  margin-left: 8px;
  margin-top: calc(44px - 100vw * 452 / 750);
  margin-bottom: 32px;
}

.income-text {
  width: 100vw;
  height: 34px;
  font-size: 45px;
  letter-spacing: 2px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #E9BE3E;
  line-height: 45px;
}

.button-onclick {
  border-bottom: 2px solid rgba(32, 64, 189, 1);
  color: rgba(32, 64, 189, 1);
}

.task-area {
  margin-top: 3px;
  padding-top: 2px;
  background-color: white;
  overflow: hidden;
}

.task-box {
  margin: 10px 7px 0px 7px;
  padding-left: 8px;
  border-radius: 4px;
  height: 78px;
  /*border-bottom: 1px solid rgba(153, 153, 153, 0.1);*/
  display: flex;
  background-color: #FFFFFF;
}

.task-context {
  width: 70%;
  height: 70px;
  background-color: #FFFFFF;

}

.img-style {
  width: 15px;
  height: 15px;
  float: left;
  margin-top: 12px;

}

.word-area {
  height: 50px;
  margin: 0 0 0 10px;
  /*padding: 20px 15px 20px 15px;*/
}

.wordsize {
  /*width: 59%;*/
  text-align: left;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 15px;
  margin-top: 8px;
  word-break: break-all;
  white-space: normal;
  line-height: 21px;
}

.wordsize2 {
  /*width: 65%;*/
  text-align: left;
  /*margin-left: -20px;*/
  margin-top: 13px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(153, 153, 153, 0.8);
  /*margin: 8px 0 0 0;*/
  line-height: 18px;
}

.task-button-area {
  /*display: in;*/
  /*height: 30px;*/
  margin: 0;
  padding: 0;
  width: 40%;
  font-size: 32px;
  word-break: break-all;
  /*overflow : hidden;*/
  /*text-overflow: ellipsis;*/
}

.button-icon {
  height: 18px;
  margin-right: 8px;
  margin-top: -4px;
}


/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

/deep/ .el-scrollbar__thumb {
  display: none;
}

.text-description {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.199);
  z-index: 2000;
}

.model-button {
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 1999;
  border: none;
  padding: 0;
}

.collapse-header-span {
  color: #ADADAD;
  font-size: 14px;
}

.collapse-footer-span {
  height: 50px;
  padding-top: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 12px;
  opacity: 0.6;
}

/deep/ .el-collapse-item__header {
  height: 80px;

}

/deep/ .el-collapse-item__arrow {
  margin-top: -20px;
  margin-right: 15px;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0px;
}
</style>